%content-block {
  background-color: getColor($c-bg-neutral);
  border-radius: 16px;
}

%content-title {
  padding-bottom: rem-calc(24);
  border-bottom: 1px solid getColor($c-border);
}

%content-block-padding {
  padding: rem-calc(32);

  @include breakpoint(small down) {
    padding: rem-calc(16);
  }
}

%base-content-block {
  padding: rem-calc(32);
  border-radius: 16px;
  border: 1px solid getColor($c-border);
  background-color: getColor($c-bg-neutral);

  @include breakpoint(small down) {
    padding: rem-calc(24);
  }
}

;@import "sass-embedded-legacy-load-done:72003";